<template>
  <div class="inline-flex align-items-center">
    <div v-tooltip="msg">
      <InputSwitch
        :disabled="disabled"
        v-model="status"
        class="mr-2"
        @change="changeSwitch($event, cardNumber)"
      />
    </div>

    <div>
      {{ text }}
    </div>
  </div>
</template>

<script>
import { changeStatus } from "../api/status";

export default {
  name: "CardsStatusSwitcher",
  props: {
    disabled: {
      type: Boolean,
      requaried: true,
    },
    valueStatus: {
      type: Boolean,
      requaried: true,
    },
    dsc: {
      type: String,
      requaried: true,
    },
    cardNumber: {
      type: Number,
      requaried: true,
    },
    tooltip: {
      type: String,
      requaried: true,
    },
  },
  data() {
    return {
      status: this.valueStatus,
      disabled: this.disabled,
      text: this.dsc,
      msg: this.tooltip,
    };
  },
  methods: {
    changeSwitch(e, card) {
      console.log(e);
      this.disabled = true;
      changeStatus(this, card);
    },
    createStatus() {
      this.$emit("setCardStatus", { status: this.text });
    },
  },
};
</script>

<style>
</style>