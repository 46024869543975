export default {
  // Типы лимитов
  TYPE_SERVICE: [
    { pk: 1, name: "В литрах" },
    { pk: 2, name: "В рублях" },
  ],
  // Период лимитов
  PERIOD_LIMIT: [
    { pk: 1, name: "Суточный", handler_type: [2] },
    { pk: 2, name: "Недельный (ПН-ВС)", handler_type: [2] },
    { pk: 3, name: "Месячный", handler_type: [2] },
    { pk: 4, name: "Квартальный", handler_type: [2] },
    { pk: 5, name: "Разовый", handler_type: [2] },
  ],
  // Тип ограничителей
  TYPE_LIMITER: [
    { pk: 1, name: "Разрешить", handler_type: [2, 4] },
    { pk: 2, name: "Запретить", handler_type: [4] },
  ],
  // Тип Карты
  TYPE_CARD: {
    1: { name: "PetrolPlus" },
    2: { name: "РН" },
    3: { name: "Лукойл" },
    4: { name: "ГПН" },
    4: { name: "Татнефть" },
    6: { name: "РН" },
  },
  // Статус компании
  STATUS: {
    1: { name: "Активна", tag: "success", class: 'bg-green-400' },
    2: { name: "Заблокирована", tag: "danger", class: 'bg-red-400' },
    3: { name: "Заблокирована вручную", tag: "" },
    4: { name: "Закрыта", tag: "" },
  },
  // Роль сотрудника
  ROLE: {
    1: { name: "Сотрудник" },
    2: { name: "Админ" },
    3: { name: "Держатель" },
  },
  APP_LINK: {
    exp: [
      {
        name: "market",
        link: "https://play.google.com/store/apps/details?id=com.dtteh",
        img: "../static/img/login-icons/google.svg",
        dsc: "Скачайте в",
        "name-store": "Google Play",
        download: false,
        imgWidth: "50",
      },
      {
        name: "appstore",
        link: "https://apps.apple.com/ru/app/%D0%B4%D0%B5%D0%BB%D1%8C%D1%82%D0%B0-%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/id1670415229?platform=iphone",
        img: "../static/img/login-icons/app-store.svg",
        dsc: "Скачайте в",
        "name-store": "Apple Store",
        download: false,
        imgWidth: "25",
      },
    ],
    dt: [
      // {
      //   name: "local",
      //   link: "static/dt-teh.apk",
      //   img: "../static/img/login-icons/android.svg",
      //   dsc: "Загрузите на",
      //   "name-store": "Android",
      //   download: true,
      // },
      {
        name: "market",
        link: "https://play.google.com/store/apps/details?id=com.dtteh",
        img: "../static/img/login-icons/google.svg",
        dsc: "Скачайте в",
        "name-store": "Google Play",
        download: false,
        imgWidth: "50",
      },
      {
        name: "appstore",
        link: "https://apps.apple.com/ru/app/%D0%B4%D0%B5%D0%BB%D1%8C%D1%82%D0%B0-%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/id1670415229?platform=iphone",
        img: "../static/img/login-icons/app-store.svg",
        dsc: "Скачайте в",
        "name-store": "Apple Store",
        download: false,
        imgWidth: "25",
      },
    ],
  },
  // CARD_DISIGN: {
  //   default: [
  //     {
  //       class: "dt",
  //       imgPath: "../static/img/logo-card/img/logo_obenwq.svg",
  //       imgWidth: "100px",
  //       cardIcon: "../static/img/mini-card/small-icon-dt.svg",
  //     },
  //     {
  //       class: "rosn",
  //       imgPath: "../static/img/logo-card/img/RNlogo.740b53c7_hct6u4.png",
  //       altText: "Логотип Роснефть",
  //       imgWidth: "50px",
  //       cardIcon: "../static/img/mini-card/small-icon-ros.svg",
  //     },
  //     {
  //       class: "luk",
  //       imgPath: "../static/img/logo-card/img/luk.png",
  //       altText: "Логотип Лукойл",
  //       imgWidth: "30px",
  //       cardIcon: "../static/img/mini-card/small-icon-luk.svg",
  //     },
  //     {
  //       class: "gpn",
  //       imgPath: "../static/img/logo-card/img/gpn-logo.2df3d2d6_pkeewa.png",
  //       altText: "Логотип ОПТИ24",
  //       imgWidth: "70px",
  //       cardIcon: "../static/img/mini-card/small-icon-opti.svg",
  //     },
  //     {
  //       class: "rosn",
  //       imgPath: "../static/img/logo-card/img/RNlogo.740b53c7_hct6u4.png",
  //       altText: "Логотип Роснефть",
  //       imgWidth: "50px",
  //       cardIcon: "../static/img/mini-card/small-icon-ros.svg",
  //     },
  //     {
  //       class: "rosn",
  //       imgPath: "../static/img/logo-card/img/RNlogo.740b53c7_hct6u4.png",
  //       altText: "Логотип Роснефть",
  //       imgWidth: "50px",
  //       cardIcon: "../static/img/mini-card/small-icon-ros.svg",
  //     },
  //   ],
  //   transit: [
  //     {
  //       class: "pp",
  //       imgPath: "../static/img/logo-card/img/petrol-logo.4b0da871_nnd5hg.png",
  //       altText: "Логотип Петрол Плюс",
  //       imgWidth: "100px",
  //       cardIcon: "../static/img/mini-card/small-icon-dt.svg",
  //     },
  //     {
  //       class: "rosn",
  //       imgPath: "../static/img/logo-card/img/RNlogo.740b53c7_hct6u4.png",
  //       altText: "Логотип Роснефть",
  //       imgWidth: "50px",
  //       cardIcon: "../static/img/mini-card/small-icon-ros.svg",
  //     },
  //     {
  //       class: "luk",
  //       imgPath: "../static/img/logo-card/img/luk.png",
  //       altText: "Логотип Лукойл",
  //       imgWidth: "30px",
  //       cardIcon: "../static/img/mini-card/small-icon-luk.svg",
  //     },
  //     {
  //       class: "gpn",
  //       imgPath: "../static/img/logo-card/img/gpn-logo.2df3d2d6_pkeewa.png",
  //       altText: "Логотип ОПТИ24",
  //       imgWidth: "70px",
  //       cardIcon: "../static/img/mini-card/small-icon-opti.svg",
  //     },
  //   ],
  //   muntrans: [
  //     {
  //       class: "pp",
  //       imgPath: "../static/img/logo-card/img/petrol-logo.4b0da871_nnd5hg.png",
  //       altText: "Логотип Петрол Плюс",
  //       imgWidth: "100px",
  //       cardIcon: "../static/img/mini-card/small-icon-dt.svg",
  //     },
  //     {
  //       class: "rosn",
  //       imgPath: "../static/img/logo-card/img/RNlogo.740b53c7_hct6u4.png",
  //       altText: "Логотип Роснефть",
  //       imgWidth: "50px",
  //       cardIcon: "../static/img/mini-card/small-icon-ros.svg",
  //     },
  //     {
  //       class: "luk",
  //       imgPath: "../static/img/logo-card/img/luk.png",
  //       altText: "Логотип Лукойл",
  //       imgWidth: "30px",
  //       cardIcon: "../static/img/mini-card/small-icon-luk.svg",
  //     },
  //     {
  //       class: "gpn",
  //       imgPath: "../static/img/logo-card/img/gpn-logo.2df3d2d6_pkeewa.png",
  //       altText: "Логотип ОПТИ24",
  //       imgWidth: "70px",
  //       cardIcon: "../static/img/mini-card/small-icon-opti.svg",
  //     },
  //     {
  //       class: "rosn",
  //       imgPath: "../static/img/logo-card/img/RNlogo.740b53c7_hct6u4.png",
  //       altText: "Логотип Роснефть",
  //       imgWidth: "50px",
  //       cardIcon: "../static/img/mini-card/small-icon-ros.svg",
  //     },
  //   ],
  //   oc1603: [
  //     {
  //       class: "dt",
  //       imgPath: "../static/img/logo-card/img/logo_obenwq.svg",
  //       imgWidth: "100px",
  //       cardIcon: "../static/img/mini-card/small-icon-dt.svg",
  //     },
  //     {
  //       class: "dt",
  //       imgPath: "../static/img/logo-card/img/logo_obenwq.svg",
  //       imgWidth: "100px",
  //       cardIcon: "../static/img/mini-card/small-icon-dt.svg",
  //     },
  //     {
  //       class: "luk",
  //       imgPath: "../static/img/logo-card/img/luk.png",
  //       altText: "Логотип Лукойл",
  //       imgWidth: "30px",
  //       cardIcon: "../static/img/mini-card/small-icon-luk.svg",
  //     },
  //     {
  //       class: "dt",
  //       imgPath: "../static/img/logo-card/img/logo_obenwq.svg",
  //       imgWidth: "100px",
  //       cardIcon: "../static/img/mini-card/small-icon-dt.svg",
  //     },
  //     {
  //       class: "rosn",
  //       imgPath: "../static/img/logo-card/img/RNlogo.740b53c7_hct6u4.png",
  //       altText: "Логотип Роснефть",
  //       imgWidth: "50px",
  //       cardIcon: "../static/img/mini-card/small-icon-ros.svg",
  //     },
  //   ],
  //   oc1575: [
  //     {
  //       class: "blue",
  //       imgPath: "../static/img/logo-card/img/logo-exp-white_xfccov.svg",
  //       imgWidth: "120px",
  //       cardIcon: "../static/img/mini-card/small-icon-blue.svg",
  //     },
  //     {
  //       class: "dt",
  //       imgPath: "../static/img/logo-card/img/logo_obenwq.svg",
  //       imgWidth: "100px",
  //       cardIcon: "../static/img/mini-card/small-icon-dt.svg",
  //     },
  //     {
  //       class: "luk",
  //       imgPath: "../static/img/logo-card/img/luk.png",
  //       altText: "Логотип Лукойл",
  //       imgWidth: "30px",
  //       cardIcon: "../static/img/mini-card/small-icon-luk.svg",
  //     },
  //     {
  //       class: "dt",
  //       imgPath: "../static/img/logo-card/img/logo_obenwq.svg",
  //       imgWidth: "100px",
  //       cardIcon: "../static/img/mini-card/small-icon-dt.svg",
  //     },
  //     {
  //       class: "rosn",
  //       imgPath: "../static/img/logo-card/img/RNlogo.740b53c7_hct6u4.png",
  //       altText: "Логотип Роснефть",
  //       imgWidth: "50px",
  //       cardIcon: "../static/img/mini-card/small-icon-ros.svg",
  //     },
  //   ],
  //   oc914: [
  //     {
  //       class: "purple",
  //       imgPath: "../static/img/logo-card/img/logo-exp-white_xfccov.svg",
  //       imgWidth: "100px",
  //       cardIcon: "../static/img/mini-card/small-icon-purple.svg",
  //     },
  //     {
  //       class: "dt",
  //       imgPath: "../static/img/logo-card/img/logo_obenwq.svg",
  //       imgWidth: "100px",
  //       cardIcon: "../static/img/mini-card/small-icon-dt.svg",
  //     },
  //     {
  //       class: "luk",
  //       imgPath: "../static/img/logo-card/img/luk.png",
  //       altText: "Логотип Лукойл",
  //       imgWidth: "30px",
  //       cardIcon: "../static/img/mini-card/small-icon-luk.svg",
  //     },
  //     {
  //       class: "dt",
  //       imgPath: "../static/img/logo-card/img/logo_obenwq.svg",
  //       imgWidth: "100px",
  //       cardIcon: "../static/img/mini-card/small-icon-dt.svg",
  //     },
  //     {
  //       class: "rosn",
  //       imgPath: "../static/img/logo-card/img/RNlogo.740b53c7_hct6u4.png",
  //       altText: "Логотип Роснефть",
  //       imgWidth: "50px",
  //       cardIcon: "../static/img/mini-card/small-icon-ros.svg",
  //     },
  //   ],
  // },
  CARD_DISIGN: {
    PPLUS: {
      oc914: {
        class: "purple",
        imgPath: "../static/img/logo-card/img/logo-exp-white_xfccov.svg",
        imgWidth: "100px",
        cardIcon: "../static/img/mini-card/small-icon-purple.svg",
      },
      oc1603: {
        class: "dt",
        imgPath: "../static/img/logo-card/img/logo_obenwq.svg",
        imgWidth: "100px",
        cardIcon: "../static/img/mini-card/small-icon-dt.svg",
      },
      oc1575: {
        class: "blue",
        imgPath: "../static/img/logo-card/img/logo-exp-white_xfccov.svg",
        imgWidth: "120px",
        cardIcon: "../static/img/mini-card/small-icon-blue.svg",
      },
      default: {
        class: "blue",
        imgPath: "../static/img/logo-card/img/logo-exp-white_xfccov.svg",
        imgWidth: "120px",
        cardIcon: "../static/img/mini-card/small-icon-blue.svg",
      },
    },
    OPTI24: {
      class: "gpn",
      imgPath: "../static/img/logo-card/img/gpn-logo.2df3d2d6_pkeewa.png",
      altText: "Логотип ОПТИ24",
      imgWidth: "70px",
      cardIcon: "../static/img/mini-card/small-icon-opti.svg",
    },
    LUKOIL: {
      class: "luk",
      imgPath: "../static/img/logo-card/img/luk.png",
      altText: "Логотип Лукойл",
      imgWidth: "30px",
      cardIcon: "../static/img/mini-card/small-icon-luk.svg",
    },
    RN: {
      class: "rosn",
      imgPath: "../static/img/logo-card/img/RNlogo.740b53c7_hct6u4.png",
      altText: "Логотип Роснефть",
      imgWidth: "50px",
      cardIcon: "../static/img/mini-card/small-icon-ros.svg",
    },
    TATNEFT: {
      class: "tatneft",
      imgPath: "../static/img/logo-card/img/tatneft-logo.svg",
      altText: "Логотип Роснефть",
      imgWidth: "70px",
      cardIcon: "../static/img/mini-card/small-icon-tatneft.svg",
    },
    DEFAULT: {
      class: "",
      imgPath: "",
      altText: "",
      imgWidth: "50px",
      cardIcon: "",
    },
  },
};
