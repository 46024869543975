<template>
  <InputText
    style="width: 30vw"
    :useGrouping="false"
    v-model.trim="valueInput"
    type="search"
    :placeholder="placeholder"
    @keypress.enter="this.$emit('updater')"
    @input="setValue($event)"
  />
</template>

<script>
export default {
  name: "TransactionFilterSearch",
  props: {
    value: {
      type: String,
      requaried: true,
    },
    placeholder: {
      type: String,
      requaried: true,
    },
  },
  data() {
    return {
      isUpdate: false,
      valueInput: this.value,
    };
  },
  methods: {
    setValue(e) {
      console.log(this.valueInput);
      this.isUpdate = true;
      this.$emit("update:value", this.valueInput);
    },
  },
  // unmounted() {
  //   if (this.isUpdate) {
  //     this.$emit("updater");
  //   }
  // },
};
</script>

<style>
</style>