<template>
  <layout-base>
    <loading-progress :loading="loading"></loading-progress>
    <MobileSpiner :loading="loading" />
    <div class="trn" v-if="$store.state.company.curCompany">
      <TabView
        v-model:activeIndex="active"
        lazy
        @tab-change="tabChange($event)"
      >
        <TabPanel header="Общие">
          <TransactionsAllTable v-model:curCompanyList="currentCompany" />
        </TabPanel>
        <TabPanel header="Транзакции по картам">
          <TransactionsCardTable v-model:curCompanyList="currentCompany" />
        </TabPanel>
        <TabPanel header="Транзакции по компаниям">
          <TransactionsCompanyTable v-model:curCompanyList="currentCompany" />
        </TabPanel>
        <TabPanel header="Транзакции по группам карт">
          <TransactionsGroupTable v-model:curCompanyList="currentCompany" />
        </TabPanel>
        <TabPanel header="Транзакции по держателям">
          <TransactionsHoldersTable v-model:curCompanyList="currentCompany" />
        </TabPanel>
        <TabPanel header="Транзакции по услугам">
          <TransactionsServicesTable v-model:curCompanyList="currentCompany" />
        </TabPanel>
      </TabView>
    </div>
  </layout-base>
</template>

<script>
import store from "@/store";

import TransactionsCardTable from "./components/TransactionsCardTable";
import TransactionsAllTable from "./components/TransactionsAllTable.vue";
import TransactionsCompanyTable from "./components/TransactionsCompanyTable.vue";
import TransactionsGroupTable from "./components/TransactionsGroupTable.vue";
import TransactionsHoldersTable from "./components/TransactionsHoldersTable.vue";
import TransactionsServicesTable from "./components/TransactionsServicesTable.vue";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
 
export default {
  components: {
    TransactionsCardTable,
    TransactionsAllTable,
    TransactionsCompanyTable,
    TransactionsGroupTable,
    TransactionsHoldersTable,
    TransactionsServicesTable,
    VueTailwindPagination,
  },
  data() {
    return {
      active: sessionStorage.getItem("trn-tab")
        ? Number(sessionStorage.getItem("trn-tab"))
        : 0,
      currentCompany: [store.state.company.curCompany?.id],
      currentPage: 1,
      perPage: 5,
      total: 20,
    };
  },
  computed: {
    getTabIndex() {
      return sessionStorage.getItem("trn-tab")
        ? Number(sessionStorage.getItem("trn-tab"))
        : 0;
    },
  },
  methods: {
    tabChange(e) {
      // console.log(e);
      sessionStorage.setItem("trn-tab", e.index);
    },
  },
};
</script>

<style lang="scss">
.trn {
  height: calc(100vh - 55px);
  @media (min-width: 1440px) {
    height: calc(100vh - 60px);
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding-top: 0.1rem;
    padding-bottom: 0.5rem;
    height: 25px !important;
  }
  .p-tabview.p-component {
    height: 100%;
  }
  .p-tabview-panels {
    // padding: 0.1rem 0 1.25rem !important;
    // background-color: var(--gray-300) !important;
    padding: 0.1rem 0 0rem !important;
    background-color: var(--gray-300) !important;
    height: 100%;
    // height: calc(100vh - 108px);
    // @media (min-width: 1440px) {
    //   height: calc(100vh - 115px);
    // }
    .p-tabview-panel {
      height: 100%;
    }
  }
  .p-component {
    font-size: 0.8rem !important;
  }
  @media (max-width: 1440px) {
    .p-component {
      font-size: 1rem !important;
    }
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.2rem 0.5rem;
  }
  .p-datatable .p-datatable-loading-overlay {
    z-index: 1 !important;
    background-color: rgba($color: #fff, $alpha: 0.95);
  }

  .p-datatable-loading-icon.pi-spin.pi.pi-spinner {
    color: var(--base) !important;
    font-size: 2.2rem;
    font-weight: 900;
    animation-duration: 1s;
  }
  .p-0-super {
    z-index: 10011 !important;
    &.p-column-filter-overlay-menu {
      .p-column-filter-buttonbar,
      .p-column-filter-constraint {
        padding: 0.5rem 0.5rem !important;
      }
      .p-column-filter-buttonbar {
        background-color: var(--gray-400) !important;
      }
    }
  }
  .p-datatable-wrapper {
    height: 100% !important;
    max-height: 100% !important;
    overflow-x: auto !important;
    background: #fff;
    .p-datatable-table {
      height: 100% !important;
      max-height: 100% !important;
      .p-datatable-tbody {
        height: 100% !important;
        max-height: 100% !important;
        .p-datatable-emptymessage {
          height: 100% !important;
          max-height: 100% !important;
        }
      }
    }
  }
  .trn-wrp {
    padding-bottom: 0px;
    height: calc(100% - 100px);
    @media (min-width: 1440px) {
      padding-bottom: 0px;
      height: calc(100% - 110px);
    }
  }
  .p-datatable .p-datatable-tbody > tr:focus {
    outline: none !important;
    background-color: #5a92f95e !important;
  }
  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > .trn-res-new:hover,
  .p-datatable .p-datatable-tbody > tr:focus {
    background-color: var(--gray-300) !important;
  }
  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.1rem 0.5rem;
    height: 35px;
  }
  .p-column-resizer {
    // background: var(--gray-400) !important;
    right: 0 !important;
    border: 0;
    &:hover {
      &::before {
        background: var(--gray-400) !important;
      }
    }

    // pi-arrows-h
    &::before {
      position: absolute;
      content: "";
      right: 0;
      width: 50%;
      height: 100%;
      background: var(--gray-300) !important;
      // border: 0 solid transparent;
      // border-top-width: 17px;
      // border-bottom-width: 17px;
      // border-right: 10px solid var(--gray-400);
    }
  }
  // .trn-all-table.p-datatable-sm .p-datatable-thead > tr > th {
  //   &::before {
  //     position: absolute;
  //     left: 0;
  //     content: "";
  //     width: 0;
  //     height: 0;
  //     border: 0 solid transparent;
  //     border-bottom-width: 10px;
  //     border-top-width: 10px;
  //     border-left: 6px solid var(--gray-400);
  //   }
  // }
}
</style>