<template>
  <div class="calendar">
    <Button
      class="p-0 p-button-sm m-0 text-sm"
      style="width: 250px; height: 2rem;"
      @click="display = !display"
    >
      <div class="btn-date flex">
        <Calendar
          :disabled="true"
          class="w-full mt-2 text-sm"
          v-model="blobDate.start"
          dateFormat="dd-mm-yy"
        />

        <Calendar
          :disabled="true"
          class="w-full mt-2"
          v-model="blobDate.end"
          dateFormat="dd-mm-yy"
        />
      </div>
    </Button>
    <Dialog
      header="Выбор периода"
      :modal="true"
      class="calendar"
      :draggable="false"
      v-model:visible="display"
      @hide="loger"
    >
      <div class="grid calendar-from-to mt-2">
        <div
          class="col-12 md:col-6 flex flex-column align-items-center justify-content-between"
        >
          <h4 class="mb-4">Начало периода</h4>
          <Calendar
            v-model="dateFrom"
            :inline="true"
            class="w-full h-full"
            dateFormat="dd.mm.yy"
            :showOnFocus="true"
          />
          <Calendar
            :showOnFocus="true"
            class="w-full mt-2"
            v-model="dateFrom"
            dateFormat="dd.mm.yy"
          />
        </div>
        <div
          class="col-12 md:col-6 flex flex-column align-items-center justify-content-between"
        >
          <h4 class="mb-4">Конец периода</h4>
          <Calendar
            v-model="dateTo"
            class="w-full h-full"
            :inline="true"
            dateFormat="dd.mm.yy"
            :showOnFocus="true"
          />
          <Calendar
            class="w-full mt-2"
            v-model="dateTo"
            dateFormat="dd.mm.yy"
            :showOnFocus="true"
          />
        </div>
      </div>
      <div class="mt-2 mb-4 grid">
        <Button
          @click="setDatePro('today')"
          class="col-12 sm:col-2 mb-2 ml-2 sm:ml-0 sm:mb-0 justify-content-start sm:justify-content-center p-button-text p-0 underline p-button-sm"
        >
          Сегодня
        </Button>
        <Button
          @click="setDatePro('yestoday')"
          class="col-12 sm:col-2 mb-2 ml-2 sm:ml-0 sm:mb-0 justify-content-start sm:justify-content-center p-button-text p-0 underline p-button-sm"
        >
          Вчера
        </Button>
        <Button
          @click="setDatePro('curMounth')"
          class="col-12 sm:col-3 mb-2 ml-2 sm:ml-0 sm:mb-0 justify-content-start sm:justify-content-center p-button-text p-0 underline p-button-sm"
        >
          Текущий месяц
        </Button>
        <Button
          @click="setDatePro('lastMounth')"
          class="col-12 sm:col-3 ml-2 sm:ml-0 justify-content-start sm:justify-content-center p-button-text p-0 underline p-button-sm"
        >
          Прошлый месяц
        </Button>
      </div>
      <Button
        class="p-button-sm"
        :disabled="!dateFrom && !dateTo"
        label="Применить"
        @click="setParentDate"
      />
    </Dialog>
  </div>
</template>

<script>
import { daysForCalendar, getShortcutDate } from "../plugins/dayjs/index";
export default {
  name: "TheCalendarFromTo",
  props: {},
  data() {
    return {
      display: false,
      dateFrom: null,
      dateTo: null,
      blobDate: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    loger() {},
    setBlob() {
      this.blobDate.start = this.dateFrom;
      this.blobDate.end = this.dateTo;
    },
    getDate(paramrtr, type) {
      return daysForCalendar(paramrtr, type);
    },
    postDateToParent() {},
    setDatePro(type) {
      switch (type) {
        case "today":
          this.dateFrom = getShortcutDate(type).start;
          this.dateTo = getShortcutDate(type).end;
          break;
        case "yestoday":
          this.dateFrom = getShortcutDate(type).start;
          this.dateTo = getShortcutDate(type).end;
          break;
        case "curMounth":
          this.dateFrom = getShortcutDate(type).start;
          this.dateTo = getShortcutDate(type).end;
          break;
        case "lastMounth":
          this.dateFrom = getShortcutDate(type).start;
          this.dateTo = getShortcutDate(type).end;
          break;
        default:
          break;
      }
    },
    setParentDate() {
      this.$emit("setDate", {
        start: this.getDate(this.dateFrom, "server-start"),
        end: this.getDate(this.dateTo, "server-end"),
      });
      this.setBlob();
      this.display = false;
    },
  },
  watch: {
    display(val) {
      if (val) {
        this.dateFrom = this.blobDate.start;
        this.dateTo = this.blobDate.end;
      }
    },
  },
  mounted() {
    // console.log(days(this));
    !this.dateFrom && !this.dateTo ? this.setDatePro("curMounth") : null;
    this.setParentDate();
    this.setBlob();
    this.$primevue.config.locale.dayNames = [
      "Воскресенье",
      "Понедельнк",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ];
    this.$primevue.config.locale.dayNamesShort = [
      "Вос",
      "Пон",
      "Вто",
      "Сре",
      "Чет",
      "Пят",
      "Суб",
    ];
    this.$primevue.config.locale.dayNamesMin = [
      "Вс",
      "Пн",
      "Вт",
      "Ср",
      "Чт",
      "Пт",
      "Сб",
    ];
    this.$primevue.config.locale.monthNames = [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ];
    this.$primevue.config.locale.monthNamesShort = [
      "Янв",
      "Фев",
      "Мар",
      "Апр",
      "Май",
      "Июн",
      "Июл",
      "Авг",
      "Сент",
      "Окт",
      "Ноя",
      "Дек",
    ];
  },
};
</script>

<style lang="scss" >
</style>