import api from "@/api/index";
import store from "@/store";
import {
    setEmoji
} from "@/handlers/emoji";

export function getGroupCart(that) {
    let id = store.state.company.curCompany ?
        store.state.company.curCompany.id :
        null;

    if (id !== null) {
        api
            .get("groups/list", {
                params: {
                    company: that.filters.company_name.value?.length === store.state.company.companyList.length ? null : that.filters.company_name.value,
                },
            })
            .then((r) => {
                console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
                that.listGroup = r.data;
            })
            .catch((err) => {
                console.log(err);
                let statusCode = err.request.status;
                let statusText = err.request.statusText;
                console.error(setEmoji("error") + statusCode + " " + statusText);
                switch (statusCode) {
                    case 0:
                        that.$toast.add({
                            severity: "warn",
                            summary: `${setEmoji("error")} Нет связи с сервером`,
                            detail: "Попробуйте позднее!",
                        });
                        break;
                    case 403:
                        that.$toast.add({
                            severity: "warn",
                            summary: `${setEmoji("lock")} Время сессии истекло`,
                            detail: "Требуется авторизация",
                            life: 5000,
                        });
                        that.$router.push("/login");
                        break;
                    case 404:
                        that.$toast.add({
                            severity: "error",
                            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                            detail: "Запрос не найден",
                            life: 5000,
                        });
                        break;
                    default:
                        that.$toast.add({
                            severity: "error",
                            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
                            detail: "Попробуйте позднее!",
                            life: 5000,
                        });
                        break;
                }
            })
            .finally(() => {
                that.loading = false;
            });
    }
}