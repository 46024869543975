import config from '@/configs'

export function getCardDesign(code, subCode, setting) {
    let param = config.CARD_DISIGN["DEFAULT"][setting];
    if (code) {
        let disignSettings = config.CARD_DISIGN;
        let subDisignSettings = config.CARD_DISIGN.PPLUS;
        let arrayKey = [];
        let subArrayKey = [];

        for (let value of Object.keys(disignSettings)) {
            arrayKey.push(value);
        }
        for (let value of Object.keys(subDisignSettings)) {
            subArrayKey.push(value);
        }
        arrayKey.forEach((element) => {
            if (element === code) {
                if (code === "PPLUS") {
                    subArrayKey.forEach((el) => {
                        if (el == subCode) {
                            param = config.CARD_DISIGN[code][subCode][setting];
                        }
                    });
                } else {
                    param = config.CARD_DISIGN[element][setting];
                }
            }
        });
    }
    return param;
};