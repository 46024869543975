<template>
  <div style="width: 20vw; max-width: 300px">
    <Slider
      v-if="slider"
      @change="changer($event)"
      class="m-3"
      v-model="valueInput"
      range
      max="100"
    ></Slider>
    <div
      class="flex w-full gap-8 align-items-center justify-content-between px-2"
    >
      <InputNumber v-model.number="valueInput[0]" placeholder="От" />
      <InputNumber v-model.number="valueInput[1]" placeholder="До" />
      <!-- <span>{{ filterModel.value ? filterModel.value[0] : 0 }}</span>
            <span>{{ filterModel.value ? filterModel.value[1] : 100 }}</span> -->
    </div>
    <div class="flex align-items-center" style="padding: 0.75rem 1.25rem">
      <Button
        @click="clearValue"
        class="p-button-text p-0 underline font-bold"
        label="Сбросить значения"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TransactionFilterFromTo",
  props: {
    value: {
      type: Array,
      requaried: true,
    },
    slider: {
      type: Boolean,
      requaried: false,
      default: true,
    },
  },
  data() {
    return {
      valueInput: this.value,
      isUpdate: false,
    };
  },
  methods: {
    changer() {
      this.isUpdate = true;
      this.$emit("update:value", this.valueInput);
    },
    clearValue() {
      this.valueInput = [];
      this.$emit("update:value", []);
    },
  },
  // unmounted() {
  //   if (this.isUpdate) {
  //     this.$emit("updater");
  //   }
  // },
};
</script>

<style>
</style>