<template>
  <TransactionsPage />
</template>

<script>
import TransactionsPage from "@/modules/trn";
export default {
  components: { TransactionsPage },
};
</script>

<style>
</style>