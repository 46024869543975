<template>
  <Listbox
    v-model="valueSelected"
    :options="itemsOption"
    :optionLabel="itemName"
    :optionValue="itemValue"
    class="w-full gh"
    listStyle="height:50vh; width: 50vw; max-width: 50vw"
    filter
    :multiple="toggleAll"
    @change="selectingItems($event)"
    emptyFilterMessage="Совпадения не найдены"
    emptyMessage="Нет данных"
  >
    <template #header>
      <!-- <div class="chips" style="padding: 0.75rem 1.25rem">
        <Chip
          class="text-sm"
          v-for="item in valueSelected"
          :label="items.filter((el) => el.id == item)[0]['name']"
          :key="item"
          @remove="removing(event)"
        />
      </div> -->
    </template>
    <template #footer>
      <div class="flex justify-content-between align-items-center">
        <div
          v-if="toggleAll"
          class="flex align-items-center"
          style="padding: 0.75rem 1.25rem"
        >
          <Checkbox
            :binary="true"
            class="mr-2"
            @change="selectAll($event)"
            v-model="selectall"
          />
          <span class="font-bold">Выбрать все</span>
        </div>
        <div class="flex align-items-center" style="padding: 0.75rem 1.25rem">
          <Button
            @click="clearSelectItems"
            class="p-button-text p-0 underline font-bold"
            label="Сбросить выбранное"
          />
        </div>
      </div>
    </template>
  </Listbox>
</template>

<script>
export default {
  name: "TransactionFilterSelect",
  props: {
    items: {
      type: Array,
      reqauried: true,
    },
    value: {
      type: Array,
      reqauried: true,
    },
    itemName: {
      type: String,
      reqauried: true,
    },
    itemValue: {
      type: String,
      reqauried: true,
    },
    toggleAll: {
      type: Boolean,
      reqauried: false,
      defailt: false,
    },
  },
  data() {
    return {
      checkedAllCompany: false,
      valueSelected: this.value,
      itemsOption: this.items,
      selectall: false,
      isUpdate: false,
    };
  },
  methods: {
    changer() {
      this.selectingItems();
    },
    selectingItems() {
      console.log(typeof this.valueSelected);
      if (typeof this.valueSelected == "array") {
        if (this.valueSelected?.length != this.items?.length) {
          this.floatSelecting();
        }
      }
      this.$emit("update:value", this.valueSelected);
      this.isUpdate = true;
    },
    floatSelecting() {
      this.valueSelected.forEach((element) => {
        // console.log(element);
        let newEl = this.itemsOption.find(
          (el) => el[this.itemValue] == element
        );
        let idx = this.itemsOption.findIndex(
          (el) => el[this.itemValue] == element
        );
        this.itemsOption.splice(idx, 1);
        this.itemsOption.unshift(newEl);
      });
    },
    selectAll(e) {
      console.log(this.selectall);
      if (this.selectall) {
        this.valueSelected = this.items.map((el) => el[this.itemValue]);
      } else {
        this.valueSelected = [];
      }
      this.selectingItems();
    },
    checkSelectAll(data) {
      // console.log(data);
      if (data?.length == this.items?.length) {
        this.selectall = true;
      } else {
        this.selectall = false;
      }
    },
    clearSelectItems() {
      this.valueSelected = [];
      this.$emit("update:value", this.valueSelected);
    },
    removing(event) {
      console.log(event);
    },
  },
  watch: {
    valueSelected(value) {
      this.checkSelectAll(value);
    },
  },
  mounted() {
    this.valueSelected &&
    (typeof this.valueSelected == "array" ||
      typeof this.valueSelected == "object")
      ? this.floatSelecting()
      : null;
    this.checkSelectAll(this.valueSelected);
  },
  // unmounted() {
  //   if (this.isUpdate) {
  //     this.$emit("updater");
  //   }
  // },
};
</script>

<style lang="scss">
.gh {
  width: 50vw !important;
  .p-listbox-list .p-listbox-item {
    padding: 0.5rem 1.25rem !important;
  }
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #495057 !important;
}

.chips {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  div {
    flex: 0 0 auto;
    margin-right: 0.25rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>