import api from "@/api/index";
import store from "@/store";
import {
  days
} from "@/plugins/dayjs/index";
import {
  setEmoji
} from "@/handlers/emoji";
import {
  timeFormater
} from "@/handlers/timeFormater";
import {
  getHandlersList
} from "./handlers";
import {
  getGroupCart
} from './group'


export function getTransactions(that) {
  /*Получение и обработка списка транзакций*/
  //   that.empty = null;
  //   Object.keys(that.transactions).forEach((element) => {
  //     that.transactions[element] = null;
  //   });
  that.trnList = []
  let url = "trn/list";
  let array;
  //   that.loading = true;
  api
    .get(url, {
      params: {
        dateStart: that.dateStartTrn,
        dateEnd: that.dateEndTrn,
        rows: that.rows,
        total_count: that.totalCount,
        page: that.page,
        // company: 0,
        company: store.state.company.curCompany.id,
        // group: that.groupTrn,
        // card: that.filltres.cardNumber ? that.filltres.cardNumber : null,
        // holder: that.filltres.holder ? that.filltres.holder : null,
        // service: that.filltres.service ? that.filltres.service.trim() : null,
        // handler_code: that.filltres.handlers ? that.filltres.handlers : null,
        // card_group: that.filltres.card_group ? that.filltres.card_group : null,
        // to_address: that.filltres.to_address ? that.filltres.to_address : null,
        // group_to: that.filltres.group_to ? that.filltres.group_to : null,
        // contract_num: that.filltres.contract_num
        //   ? that.filltres.contract_num
        //   : null,
        // to_code: that.filltres.to_code ? that.filltres.to_code : null,
        // amount_from: that.filltres.amount.from
        //   ? that.filltres.amount.from
        //   : null,
        // amount_to: that.filltres.amount.to ? that.filltres.amount.to : null,
        // price_from: that.filltres.price.from ? that.filltres.price.from : null,
        // price_to: that.filltres.price.to ? that.filltres.price.to : null,
        // sum_from: that.filltres.sum.from ? that.filltres.sum.from : null,
        // sum_to: that.filltres.sum.to ? that.filltres.sum.to : null,

        sort: that.sort,
        desc: that.desc,
      },
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      array = response.data;
      //   if (response.data.length !== 0) {
      //     that.totalRecords = response.data[0].total_count;
      //     // that.pageTrn = 1;
      //     // that.empty = false;
      //     response.data[0].total_count <= that.rowsTrn
      //       ? (that.isPaginator = false)
      //       : (that.isPaginator = true);
      //     let key = that.groupTrn !== null ? that.groupTrn : "all";
      //     that.transactions[key] = response.data;
      //   } else {
      //     Object.keys(that.transactions).forEach((element) => {
      //       that.transactions[element] = null;
      //     });
      //     that.isPaginator = false;
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      getTotalInTrn(that, array);
    });
}

export function getTotalInTrn(that, array) {
  api
    .get("trn/list", {
      params: {
        dateStart: that.dateStartTrn,
        dateEnd: that.dateEndTrn,
        // dateStart: "2022-05-21T00:00:00",
        // dateEnd: "2023-06-21T23:59:59",
        // rows: that.rowsTrn,
        // page: that.pageTrn,
        // company: that.companyTrn,
        rows: that.rows,
        // total_count: that.rowsTrn,
        page: that.page,
        // company: 0,
        company: store.state.company.curCompany.id,
        // card: that.cardTrn,
        group: "total",
        // card: that.filltres.cardNumber ? that.filltres.cardNumber : null,
        // holder: that.filltres.holder ? that.filltres.holder : null,
        // service: that.filltres.service ? that.filltres.service.trim() : null,
        // handler_code: that.filltres.handlers ? that.filltres.handlers : null,

        // card_group: that.filltres.card_group ? that.filltres.card_group : null,
        // to_address: that.filltres.to_address ? that.filltres.to_address : null,
        // group_to: that.filltres.group_to ? that.filltres.group_to : null,
        // contract_num: that.filltres.contract_num
        //   ? that.filltres.contract_num
        //   : null,
        // to_code: that.filltres.to_code ? that.filltres.to_code : null,
        // amount_from: that.filltres.amount.from
        //   ? that.filltres.amount.from
        //   : null,
        // amount_to: that.filltres.amount.to ? that.filltres.amount.to : null,
        // price_from: that.filltres.price.from ? that.filltres.price.from : null,
        // price_to: that.filltres.price.to ? that.filltres.price.to : null,
        // sum_from: that.filltres.sum.from ? that.filltres.sum.from : null,
        // sum_to: that.filltres.sum.to ? that.filltres.sum.to : null,
      },
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      array.push(response.data);
      that.trnList = array;
      that.totalCount = array[0]["total_count"];
      // that.transactionsList = that.transactionsList.concat(that.transactionsListTotal)
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}

export function getTransactionsNew(that) {
  /*Получение и обработка списка транзакций*/
  //   that.empty = null;
  //   Object.keys(that.transactions).forEach((element) => {
  //     that.transactions[element] = null;
  //   });
  that.trnList = []
  let url = "trn_v1/list";
  let array;
  //   that.loading = true;
  api
    .get(url, {
      params: {
        dateStart: that.dateStartTrn,
        dateEnd: that.dateEndTrn,
        rows: that.rows,
        total_count: that.totalCount,
        page: that.page,
        // company: 0,
        company: that.filters.company_name.value?.length === store.state.company.companyList.length ? 0 : that.filters.company_name.value,
        // group: that.groupTrn,
        card: that.filters.card_number.value ? that.filters.card_number.value : null,
        holder: that.filters.holder.value ? that.filters.holder.value : null,
        service: that.filters.service_name.value ? that.filters.service_name.value : null,
        handler_code: that.filters.handler_name.value ? that.filters.handler_name.value : null,
        card_group: that.filters.card_group.value ? that.filters.card_group.value : null,
        to_address: that.filters.to_address.value ? that.filters.to_address.value : null,
        // group_to: that.filltres.group_to ? that.filltres.group_to : null,
        contract_num: that.filters.contract_num.value ?
          that.filters.contract_num.value : null,
        // to_code: that.filltres.to_code ? that.filltres.to_code : null,
        amount_from: that.filters.amount.value.length != 0 ?
          that.filters.amount.value[0] : null,
        amount_to: that.filters.amount.value.length != 0 ? that.filters.amount.value[1] : null,
        price_from: that.filters.price.value.length != 0 ? that.filters.price.value[0] : null,
        price_to: that.filters.price.value.length != 0 ? that.filters.price.value[1] : null,
        price_to_from: that.filters.price_to.value.length != 0 ? that.filters.price_to.value[0] : null,
        price_to_to: that.filters.price_to.value.length != 0 ? that.filters.price_to.value[1] : null,
        sum_from: that.filters.sum.value.length ? that.filters.sum.value[0] : null,
        sum_to: that.filters.sum.value.length ? that.filters.sum.value[1] : null,
        sum_to_from: that.filters.sum_to.value.length ? that.filters.sum_to.value[0] : null,
        sum_to_to: that.filters.sum_to.value.length ? that.filters.sum_to.value[1] : null,
        sort: that.sort,
        desc: that.desc,
      },
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      // console.log(response.data.result);
      // console.log(!response.data.result.length);
      if (response.data.result.length) {
        array = [...response.data.result, ...[response.data.total]];
        that.trnList = array;
        that.totalCount = response.data.total["count"];
      }
      // console.log(that.trnList);
      //   if (response.data.length !== 0) {
      //     that.totalRecords = response.data[0].total_count;
      //     // that.pageTrn = 1;
      //     // that.empty = false;
      //     response.data[0].total_count <= that.rowsTrn
      //       ? (that.isPaginator = false)
      //       : (that.isPaginator = true);
      //     let key = that.groupTrn !== null ? that.groupTrn : "all";
      //     that.transactions[key] = response.data;
      //   } else {
      //     Object.keys(that.transactions).forEach((element) => {
      //       that.transactions[element] = null;
      //     });
      //     that.isPaginator = false;
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
      !that.handlersList.length ? getHandlersList(that) : null
      getGroupCart(that);
    });
}
export function getTransactionsCard(that) {
  that.trnList = []
  let url = "trn_v1/list";
  let array;
  api
    .get(url, {
      params: {
        dateStart: that.dateStartTrn,
        dateEnd: that.dateEndTrn,
        rows: that.rows,
        total_count: that.totalCount,
        page: that.page,
        company: that.filters.company_name.value?.length === store.state.company.companyList.length ? 0 : that.filters.company_name.value,
        // group: that.groupTrn,
        card: that.filters.card_number.value,
        // holder: that.filltres.holder ? that.filltres.holder : null,
        // service: that.filltres.service ? that.filltres.service.trim() : null,
        // handler_code: that.filltres.handlers ? that.filltres.handlers : null,
        // card_group: that.filltres.card_group ? that.filltres.card_group : null,
        // to_address: that.filltres.to_address ? that.filltres.to_address : null,
        // group_to: that.filltres.group_to ? that.filltres.group_to : null,
        // contract_num: that.filltres.contract_num
        //   ? that.filltres.contract_num
        //   : null,
        // to_code: that.filltres.to_code ? that.filltres.to_code : null,
        // amount_from: that.filltres.amount.from
        //   ? that.filltres.amount.from
        //   : null,
        // amount_to: that.filltres.amount.to ? that.filltres.amount.to : null,
        // price_from: that.filters.price.value.length != 0 ? that.filters.price.value[0] : null,
        // price_to: that.filters.price.value.length != 0 ? that.filters.price.value[1] : null,
        // sum_from: that.filltres.sum.from ? that.filltres.sum.from : null,
        // sum_to: that.filltres.sum.to ? that.filltres.sum.to : null,
        // sum_from: that.filters.sum.value.length ? that.filters.sum.value[0] : null,
        // sum_to: that.filters.sum.value.length ? that.filters.sum.value[1] : null,
        // sum_to_from: that.filters.sum_to.value.length ? that.filters.sum_to.value[0] : null,
        // sum_to_to: that.filters.sum_to.value.length ? that.filters.sum_to.value[1] : null, 
        sort: that.sort,
        desc: that.desc,
        group: 'card'
      },
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      // console.log(response);

      if (response.data.result.length) {
        array = [...response.data.result, ...[response.data.total]];
        that.totalCount = response.data.total["count"];
        that.trnList = array;
      }

    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}
export function getTransactionsCompany(that) {
  that.trnList = []
  let url = "trn_v1/list";
  let array;
  api
    .get(url, {
      params: {
        dateStart: that.dateStartTrn,
        dateEnd: that.dateEndTrn,
        rows: that.rows,
        total_count: that.totalCount,
        page: that.page,
        company: that.filters.company_name.value?.length === store.state.company.companyList.length ? 0 : that.filters.company_name.value,
        // group: that.groupTrn,
        card: that.filters.card_number.value,
        // holder: that.filltres.holder ? that.filltres.holder : null,
        // service: that.filltres.service ? that.filltres.service.trim() : null,
        // handler_code: that.filltres.handlers ? that.filltres.handlers : null,
        // card_group: that.filltres.card_group ? that.filltres.card_group : null,
        // to_address: that.filltres.to_address ? that.filltres.to_address : null,
        // group_to: that.filltres.group_to ? that.filltres.group_to : null,
        // contract_num: that.filltres.contract_num
        //   ? that.filltres.contract_num
        //   : null,
        // to_code: that.filltres.to_code ? that.filltres.to_code : null,
        // amount_from: that.filltres.amount.from
        //   ? that.filltres.amount.from
        //   : null,
        // amount_to: that.filltres.amount.to ? that.filltres.amount.to : null,
        // price_from: that.filters.price.value.length != 0 ? that.filters.price.value[0] : null,
        // price_to: that.filters.price.value.length != 0 ? that.filters.price.value[1] : null,
        // sum_from: that.filltres.sum.from ? that.filltres.sum.from : null,
        // sum_to: that.filltres.sum.to ? that.filltres.sum.to : null,

        sort: that.sort,
        desc: that.desc,
        group: 'company'
      },
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      // console.log(response);
      if (response.data.result.length) {
        array = [...response.data.result, ...[response.data.total]];
        that.totalCount = response.data.total["count"];
        that.trnList = array;
      }

    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}
export function getTransactionsGroup(that) {
  that.trnList = []
  let url = "trn_v1/list";
  let array;
  api
    .get(url, {
      params: {
        dateStart: that.dateStartTrn,
        dateEnd: that.dateEndTrn,
        rows: that.rows,
        total_count: that.totalCount,
        page: that.page,
        company: that.filters.company_name.value?.length === store.state.company.companyList.length ? 0 : that.filters.company_name.value,
        card_group: that.filters.card_group.value ? that.filters.card_group.value : null,

        sort: that.sort,
        desc: that.desc,
        group: 'group_by_card'
      },
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      // console.log(response);
      if (response.data.result.length) {
        array = [...response.data.result, ...[response.data.total]];
        that.totalCount = response.data.total["count"];
        that.trnList = array;
      }

    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
      getGroupCart(that);
    });
}
export function getTransactionsHolder(that) {
  that.trnList = []
  let url = "trn_v1/list";
  let array;
  api
    .get(url, {
      params: {
        dateStart: that.dateStartTrn,
        dateEnd: that.dateEndTrn,
        rows: that.rows,
        total_count: that.totalCount,
        page: that.page,
        company: that.filters.company_name.value?.length === store.state.company.companyList.length ? 0 : that.filters.company_name.value,
        holder: that.filters.holder.value ? that.filters.holder.value : null,
        sort: that.sort,
        desc: that.desc,
        group: 'holder'
      },
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      // console.log(response);
      if (response.data.result.length) {
        array = [...response.data.result, ...[response.data.total]];
        that.totalCount = response.data.total["count"];
        that.trnList = array;
      }

    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}
export function getTransactionsServices(that) {
  that.trnList = []
  let url = "trn_v1/list";
  let array;
  api
    .get(url, {
      params: {
        dateStart: that.dateStartTrn,
        dateEnd: that.dateEndTrn,
        rows: that.rows,
        total_count: that.totalCount,
        page: that.page,
        company: that.filters.company_name.value?.length === store.state.company.companyList.length ? 0 : that.filters.company_name.value,
        service: that.filters.service_name.value ? that.filters.service_name.value : null,

        sort: that.sort,
        desc: that.desc,
        group: 'service'
      },
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      // console.log(response);
      if (response.data.result.length) {
        array = [...response.data.result, ...[response.data.total]];
        that.totalCount = response.data.total["count"];
        that.trnList = array;
      }

    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}
export function getTrnReport(that) {
  console.log(that.hiddenColumns);
  api
    .get("report/get_trns", {
      responseType: "blob",
      params: {
        dateStart: that.dateStartTrn,
        dateEnd: that.dateEndTrn,
        file_type: 'xlsx',
        report_type: 'trn_page_report',
        to: that.hiddenColumns && that.hiddenColumns.includes('price_to') ? null : '',
        to_group: that.hiddenColumns && that.hiddenColumns.includes('group_to') ? null : '',
        address: that.hiddenColumns && that.hiddenColumns.includes('to_address') ? null : '',
        num_contract: that.hiddenColumns && that.hiddenColumns.includes('contract_num') ? null : '',
        code_to: that.hiddenColumns && that.hiddenColumns.includes('to_code') ? null : '',
        date_contract: that.hiddenColumns && that.hiddenColumns.includes('contract_date') ? null : '',
        holder_name: that.hiddenColumns && that.hiddenColumns.includes('holder') ? null : '',
        date_field: that.hiddenColumns && that.hiddenColumns.includes('date') ? null : '',
        group_card: that.hiddenColumns && that.hiddenColumns.includes('card_group') ? null : '',
        handler: that.hiddenColumns && that.hiddenColumns.includes('handler_name') ? null : '',
        amount: that.hiddenColumns && that.hiddenColumns.includes('amount') ? null : '',
        price: that.hiddenColumns && that.hiddenColumns.includes('price') ? null : '',
        to_price: that.hiddenColumns && that.hiddenColumns.includes('price_to') ? null : '',
        sum: that.hiddenColumns && that.hiddenColumns.includes('sum') ? null : '',
        to_sum: that.hiddenColumns && that.hiddenColumns.includes('sum_to') ? null : '',
        service_name: that.hiddenColumns && that.hiddenColumns.includes('service_name') ? null : '',
        card_number: '',
        companies: '',
        company: that.filters.company_name.value.length === store.state.company.companyList.length ? 0 : that.filters.company_name.value,
        cards: that.filters.card_number.value ? [that.filters.card_number.value] : null,
        holder: that.filters.holder.value ? that.filters.holder.value : null,
        service: that.filters.service_name.value ? that.filters.service_name.value : null,
        handler_code: that.filters.handler_name.value ? that.filters.handler_name.value : null,
        card_group: that.filters.card_group.value ? that.filters.card_group.value : null,
        to_address: that.filters.to_address.value ? that.filters.to_address.value : null,
        // group_to: that.filltres.group_to ? that.filltres.group_to : null,
        contract_num: that.filters.contract_num.value ?
          that.filters.contract_num.value : null,
        // to_code: that.filltres.to_code ? that.filltres.to_code : null,
        amount_from: that.filters.amount.value.length != 0 ?
          that.filters.amount.value[0] : null,
        amount_to: that.filters.amount.value.length != 0 ? that.filters.amount.value[1] : null,
        price_from: that.filters.price.value.length != 0 ? that.filters.price.value[0] : null,
        price_to: that.filters.price.value.length != 0 ? that.filters.price.value[1] : null,
        sum_from: that.filters.sum.value.length ? that.filters.sum.value[0] : null,
        sum_to: that.filters.sum.value.length ? that.filters.sum.value[1] : null,

      },
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      const href = URL.createObjectURL(r.data);
      const format = "xlsx";
      const nameFile =
        "Оборот по обслуживанию " +
        timeFormater(that.dateStartTrn) +
        " - " +
        timeFormater(that.dateEndTrn) +
        "." +
        format;
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", nameFile); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 423:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Следующий отчет можно будет сформировать через 10 секунд",
            life: 5000,
          });
          break;
        case 412:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Данные отсутсвуют. Поменяйте параметры запроса",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}