import store from '@/store'

export function resetFilters(that) {
    console.log(Object.keys(that.filters));
    let arr = Object.keys(that.filters);
    arr.forEach((element) => {
        if (element !== 'company_name') {
            if (that.filters[element].type) {
                console.log(that.filters[element].value);
                that.filters[element].value = [];
            } else {
                that.filters[element].value = null;
            }
        }
    });
    // that.filters.company_name.value = [store.state.company.curCompany?.id]
    // that.$emit("update:curCompanyList", that.filters.company_name.value);
    that.loadTrn();
}