<template>
  <Button @click="toggle" class="p-button-text p-0 p-button-sm">{{
    cardNumber
  }}</Button>
  <!-- <Dialog
    v-model:visible="display"
    modal
    header="Header"
    :style="{ width: '50vw' }"
  >
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </p>
  </Dialog> -->
  <OverlayPanel showCloseIcon ref="opcontrolcard" class="control-card">
    <div v-if="!loading">
      <h3 class="mb-4">Быстрое управление картой: {{ cardNumber }}</h3>
      <div v-if="card.length !== 0">
        <div class="mb-4">
          <div v-if="$store.state.curUser && $store.state.curUser.edit_card">
            <div v-if="$store.state.curUser && card.card_type.block">
              <CardsStatusSwitcher
                :disabled="
                  ($store.state.curUser.edit_card &&
                    card.status.pk == 2 &&
                    card.card_type.block) ||
                  card.disabled
                "
                :valueStatus="card.checkStatus"
                :tooltip="
                  getTooltipSwitcher(
                    card.checkMess,
                    card.status.pk,
                    $store.state.curUser.edit_card,
                    card.card_type.block
                  )
                "
                :dsc="card.status.name"
                :cardNumber="card.number"
                @setCardStatus="setStatus"
              />
            </div>
            <div v-else>
              {{ card.status.name }}
            </div>
          </div>
          <div v-else>
            {{ card.status.name }}
          </div>
        </div>
        <router-link
          style="color: var(--base2)"
          :to="{
            name: 'CardPage',
            params: {
              id: cardNumber,
            },
          }"
        >
          <div class="flex align-items-center cursor-pointer">
            <span style="letter-spacing: 0.051rem">
              Перейти в расширенное управление картой
              <i class="pi pi-angle-right"></i
            ></span>
          </div>
        </router-link>
      </div>
      <div v-else>Управление картой недоступно</div>
    </div>
    <ProgressSpinner
      v-else
      style="width: 30px; height: 30px"
      strokeWidth="6"
      animationDuration=".5s"
      aria-label="Custom ProgressSpinner"
    />
  </OverlayPanel>
</template>

<script>
import CardsStatusSwitcher from "./CardsStatusSwitcher.vue";
import { getCard } from "../api/card";
export default {
  components: { CardsStatusSwitcher },
  name: "ControlCard",
  props: {
    cardNumber: String,
    requaried: true,
  },
  data() {
    return {
      display: false,
      cardId: this.cardNumber,
      loading: true,
      card: [],
    };
  },
  methods: {
    toggle() {
      this.loading = true;
      this.$refs.opcontrolcard.toggle(event);

      setTimeout(() => {
        getCard(this);
      }, Math.random() * 1000 + 250);
    },
    getTooltipSwitcher(msg, status, edit, block) {
      if (status === 2) {
        return "Для разблокировки карты обратитесь к Вашему менеджеру";
      } else if (!edit && !block) {
        return "У данного типа карты нельзя изменить статус вручную";
      } else {
        return msg;
      }
    },
    setStatus(data) {
      this.card.status.name = data.status;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.control-card {
  .p-overlaypanel-content {
    position: relative;
    min-width: 240px;
    min-height: 100px;
  }
  .p-progress-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .p-progress-spinner-circle {
    stroke: var(--base) !important;
  }
  .p-progress-spinner-svg {
    width: 30px !important;
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled) .p-inputswitch-slider {
    background-color: #79e789 !important;
  }

  .p-inputswitch:not(.p-disabled) .p-inputswitch-slider {
    background: #f68192 !important;
  }
}
</style>