<template>
  <div
    class="table-header-container mt-0 flex bg-gray-300 p-1 justify-content-between"
  >
    <div class="p-inputgroup sm:max-w-20rem w-full sm:w-9"></div>
    <div class="align-items-stretch">
      <div class="flex align-items-stretch">
        <TheCalendarFromTo class="mr-2" v-on:setDate="getDateFromComponent" />
        <Button
          class="mr-2 flex lg:hidden"
          icon="pi pi-filter"
          @click="visible = true"
        />
        <Button
          style="width: 2rem; height: 2rem"
          class="mr-2"
          v-tooltip.left="'Сбросить все фильтры'"
          @click="clearFilters"
          icon="pi pi-filter-slash"
        />
        <Button
          style="width: 2rem; height: 2rem"
          class="mr-2"
          v-tooltip.left="'Обновить данные'"
          @click="loadTrn"
          icon="pi pi-refresh"
        />
      </div>
    </div>
  </div>
  <div class="trn-wrp bg-gray-300">
    <DataTable
      class="p-datatable-sm trn-all-table h-full w-full hidden lg:block"
      :value="trnList"
      v-model:filters="filters"
      :loading="loading"
      lazy
      scrollable
      scrollHeight="460px"
      showGridlines
      filterDisplay="menu"
      resizableColumns
      columnResizeMode="fit"
      dataKey="number"
      :rowClass="rowClass"
      removableSort
      @filter="applayFilters($event)"
      @sort="onSort($event)"
      ref="trn-all-table"
    >
      <template #empty>
        <div
          class="flex justify-content-center flex-column align-items-center my-6 w-full"
        >
          <i class="pi pi-inbox text-6xl mb-4 text-gray-700"> </i>
          <span class="text-gray-500">Нет данных</span>
        </div>
      </template>
      <Column
        field="row_number"
        :header="totalCount"
        headerStyle="max-width: 3rem; width: 3rem;  min-width: 3rem"
        bodyStyle="max-width: 3rem; width: 3rem; min-width: 3rem"
      >
        <template #body="{ data }">
          {{ data.row_number ? data.row_number : " Итог:" }}
        </template></Column
      >
      <Column
        ref="col"
        field="card_number"
        header="Номер карты"
        :showFilterMatchModes="false"
        :showClearButton="false"
        :filterMenuClass="'p-0-super'"
        sortable
        headerStyle="  min-width: 14rem"
        bodyStyle=" min-width: 14rem"
      >
        <template #body="{ data }">
          <div style="min-width: 90px !important; overflow: hidden">
            <img
              style="margin-right: 1rem; width: 1.5rem"
              :src="
                getCardDesignTrn(data.card_type, data.handler_code, 'cardIcon')
              "
            />
            <span> {{ data.card_number }}</span>
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <transaction-filter-search
            v-model:value="filterModel.value"
            :placeholder="'Введите номер карты'"
            @updater="filterCallback"
          />
        </template>
      </Column>

      <Column
        ref="col"
        field="company_name"
        header="Компания"
        :showFilterMatchModes="false"
        :showClearButton="false"
        :filterMenuClass="'p-0-super'"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <TransactionFilterSelect
            toggleAll
            :items="companyList"
            :itemName="'name'"
            :itemValue="'id'"
            v-model:value="filterModel.value"
            @updater="filterCallback"
          />
        </template>
      </Column>
      <Column ref="col" field="count" header="Транзакции"></Column>
      <Column ref="col" field="amount" header="Кол-во" sortable></Column>

      <Column
        ref="col"
        field="sum"
        header="Сумма"
        :showFilterMatchModes="false"
        :showClearButton="false"
        :filterMenuClass="'p-0-super'"
        sortable
      >
        <!-- <template #filter="{ filterModel, filterCallback }">
          <TransactionFilterFromTo
            v-model:value="filterModel.value"
            @updater="filterCallback"
          /> </template
      > -->
      </Column>
      <div v-if="$store.state.curUser && !$store.state.curUser.hide_price">
        <Column
          ref="col"
          field="sum_to"
          header="Сумма ТО"
          :showFilterMatchModes="false"
          :showClearButton="false"
          :filterMenuClass="'p-0-super'"
          sortable
        >
          <!-- <template #filter="{ filterModel, filterCallback }">
            <TransactionFilterFromTo
              v-model:value="filterModel.value"
              @updater="filterCallback"
            /> </template
        > -->
        </Column>
      </div>
    </DataTable>
    <DataView class="w-full block lg:hidden" :value="trnList" lazy>
      <template #list="{ data }">
        <div v-if="data.row_number" class="col-12 my-2 flex px-2">
          <!-- <div class="flex flex-column align-items-center" style="width: 2rem">
          <span
            class="bg-green-500 text-0 flex align-items-center justify-content-center border-circle"
            style="min-width: 2.5rem; min-height: 2.5rem"
          >
            <span style="line-height: 0" class="text-xl">{{ data.row_number }}</span>
          </span>
          <div
            class="h-full bg-green-500"
            style="width: 2px; min-height: 4rem"
          ></div>
        </div> -->
          <div
            v-if="$store.state.curUser && !loading"
            class="surface-card shadow-2 border-round p-3 flex-auto"
          >
            <div
              class="mb-3 flex flex-wrap-reverse align-items-center justify-content-between"
            >
              <span class="text-900 font-medium inline-block mr-3"
                >Карта:
                <i class="mt-2 sm:mt-0 inline-block">{{
                  data.card_number
                }}</i></span
              >
              <span class="text-500 text-sm">{{
                formaterDate(data.date)
              }}</span>
            </div>
            <span class="text-900 font-medium inline-block mr-3"
              >Держатель:
              <i>{{
                data.holder ? data.holder.name : " Без держателя"
              }}</i></span
            >
            <div class="flex align-items-stretch flex-wrap text-sm">
              <div class="mr-5 mt-3">
                <span class="font-medium text-500">Услуга</span>
                <div class="text-700 mt-2">
                  <b> {{ data.service_name }}</b>
                </div>
              </div>
              <div class="mr-5 mt-3">
                <span class="font-medium text-500">Количество</span>
                <div class="text-700 mt-2">
                  <b> {{ data.amount }}</b>
                </div>
              </div>
              <div v-if="!$store.state.curUser.hide_price">
                <div class="mr-5 mt-3">
                  <span class="font-medium text-500">Сумма/Сумма ТО</span>
                  <div class="text-700 mt-2">
                    <b> {{ data.sum + "/" + data.sum_to }}</b>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="mr-5 mt-3">
                  <span class="font-medium text-500">Сумма</span>
                  <div class="text-700 mt-2">
                    <b> {{ data.sum }}</b>
                  </div>
                </div>
              </div>
              <div v-if="!$store.state.curUser.hide_price">
                <div class="mr-5 mt-3">
                  <span class="font-medium text-500">Цена/Цена ТО</span>
                  <div class="text-700 mt-2">
                    <b> {{ data.price + "/" + data.price_to }}</b>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="mr-5 mt-3">
                  <span class="font-medium text-500">Цена</span>
                  <div class="text-700 mt-2">
                    <b> {{ data.price }}</b>
                  </div>
                </div>
              </div>
              <div class="mr-5 mt-3">
                <span class="font-medium text-500">ТО</span>
                <div class="text-700 mt-2">
                  <b> {{ data.group_to }}</b>
                </div>
              </div>
              <div class="mt-3 w-full">
                <span class="font-medium text-500">Компания</span>
                <div class="text-700 mt-2">
                  <b> {{ data.company_name }}</b>
                </div>
              </div>
              <div class="mt-3">
                <span class="font-medium text-500">Адрес</span>
                <div class="text-700 mt-2">
                  <b> {{ data.to_address }}</b>
                </div>
              </div>
            </div>
          </div>
          <Skeleton v-else style="width: 100%; height: 180px" />
        </div>
      </template>
      <template #header>
        <div v-if="trnList.length">
          <h3 class="mb-2">Общий итог</h3>
          <ul class="flex gap-2" style="list-style: none">
            <li class="flex flex-wrap">
              Кол-во: {{ trnList[trnList?.length - 1]["amount"] }}
            </li>
            <li class="flex flex-wrap">
              Сумма: {{ trnList[trnList?.length - 1]["sum"] }}
            </li>
            <li
              v-if="$store.state.curUser && !$store.state.curUser.hide_price"
              class="flex flex-wrap"
            >
              Сумма ТО: {{ trnList[trnList?.length - 1]["sum_to"] }}
            </li>
            <li class="flex flex-wrap">
              Средняя цена: {{ trnList[trnList?.length - 1]["average"] }}
            </li>
          </ul>
        </div>
      </template>
    </DataView>
    <!-- <Paginator
      @page="onPage($event)"
      :totalRecords="totalCount"
      :page="page"
      :rows="rows"
      class="bg-gray-300 fixed left-0 right-0 trn-all-pgn"
      :rowsPerPageOptions="[10, 30, 50, 100, 300, 500]"
      :template="widthWindow"
      currentPageReportTemplate="из {totalPages}"
    /> -->
    <the-paginator
      class="bg-gray-300 relative left-0 right-0 trn-all-pgn pt-2"
      @page="onPage"
      :totalRecords="Boolean(totalCount) ? totalCount : 1"
      :rows="rows"
      :page="page"
      :globalDisabel="loading"
      :rowsPerPageOptions="[30, 50, 100, 300, 500, 1000]"
    />
  </div>

  <Sidebar position="full" v-model:visible="visible">
    <div class="mb-4">
      <h2>Фильтры</h2>
    </div>
    <div class="mb-8">
      <div class="flex-auto mb-4">
        <label for="integeronly" class="font-bold block mb-2">
          Номер карты
        </label>
        <InputText
          :useGrouping="false"
          v-model.trim="filters.card_number.value"
          class="w-full"
          placeholder="Введите номер карты"
        />
      </div>
      <div class="flex-auto mb-4">
        <label for="integeronly" class="font-bold block mb-2"> Компании </label>

        <MultiSelect
          display="chip"
          v-model="filters.company_name.value"
          :options="companyList"
          filter
          :showToggleAll="false"
          panelClass="w-full panel-trn"
          style="z-index: 11015 !important"
          panelStyle="z-index: 11015"
          optionLabel="name"
          optionValue="id"
          placeholder="Выберите компанию"
          class="w-full"
          ref="selecting"
          scrollHeight="200px"
          emptyFilterMessage="Нет данных"
          emptyMessage="Нет данных"
        >
        </MultiSelect>
      </div>
    </div>
    <div class="w-full">
      <Button class="w-full" @click="applayFilters" label="Применить" />
    </div>
  </Sidebar>
</template>

<script>
import store from "@/store";

import TheCalendarFromTo from "@/components/TheCalendarFromTo.vue";
import TransactionFilterSelect from "./Filters/TransactionFilterSelect";
import TransactionFilterSearch from "./Filters/TransactionFilterSearch.vue";
import TransactionFilterFromTo from "./Filters/TransactionFilterFromTo";

import { getTransactionsCard } from "../api/trn";
import { timeFormater } from "@/handlers/timeFormater";
import { getCardDesign } from "@/handlers/cardDesign";
import { resetFilters } from "../utils/resetFilters";
export default {
  name: "TransactionsAllTable",
  components: {
    TheCalendarFromTo,
    TransactionFilterSearch,
    TransactionFilterSelect,
    TransactionFilterFromTo,
  },
  props: {
    curCompanyList: {
      type: Array,
      requaried: true,
    },
  },
  data() {
    return {
      trnList: [],
      loading: false,
      totalCount: null,
      page: 1,
      rows: 1000,
      dateStartTrn: null,
      dateEndTrn: null,
      sort: true,
      desc: null,
      filters: {
        card_number: { value: null },
        sum: { value: [], type: "array" },
        sum_to: { value: [], type: "array" },
        holder: {
          value: null,
        },
        company_name: {
          value: this.curCompanyList,
        },
        status: {
          value: null,
        },
        group: {
          value: null,
        },
        handler_name: {
          value: null,
        },
      },
      handlersList: [],
      visible: false,
    };
  },
  methods: {
    rowClass(data) {
      return [
        {
          "bg-gray-300 font-bold text-black sticky bottom-0": !data.row_number,
        },
      ];
    },
    // onPage(event) {
    //   if (this.page != event.page + 1 || this.rows != event.rows) {
    //     this.page = event.page + 1;
    //     this.rows = event.rows;
    //     this.loadTrn();
    //   }
    // },
    onPage(data) {
      this.page = data.page;
      this.rows = data.rows;
      this.loadTrn();
    },
    loadTrn() {
      this.loading = true;
      setTimeout(() => {
        getTransactionsCard(this);
      }, Math.random() * 1000 + 250);
    },
    applayFilters(event) {
      let isEqual =
        [...event.filters.company_name.value].length ===
          [...this.curCompanyList].length &&
        [...event.filters.company_name.value].every(
          (value, index) => value === [...this.curCompanyList][index]
        );
      if (!isEqual) {
        this.$emit("update:curCompanyList", event.filters.company_name.value);
      }
      this.visible = false;
      this.loadTrn();
    },
    formaterDate(timeValue, hour) {
      return timeFormater(timeValue, hour);
    },
    getDateFromComponent(data) {
      this.dateStartTrn = data.start;
      this.dateEndTrn = data.end;
      this.loadTrn();
    },
    onSort(event) {
      console.log(event);
      this.sort = event.sortField;
      event.sortOrder == 1 ? (this.desc = true) : (this.desc = null);
      this.loadTrn();
    },
    getCardDesignTrn(code, subCode, setting) {
      return getCardDesign(code, subCode, setting);
    },
    updateCompanyInParent() {
      this.$emit("update:curCompanyList", this.filters.company_name.value);
    },
    clearFilters() {
      // console.log(Object.keys(this.filters));
      // let arr = Object.keys(this.filters);
      // arr.forEach((element) => {
      //   if (this.filters[element].type) {
      //     console.log(this.filters[element].value);
      //     this.filters[element].value = [];
      //   } else {
      //     this.filters[element].value = null;
      //   }
      // });
      // this.loadTrn();
      resetFilters(this);
    },
  },
  mounted() {
    // this.loadTrn();
    // this.columns = this.$refs["trn-all-table"].columns
    //   .map(function (el) {
    //     return {
    //       name: el.props.header,
    //       value: el.props.field,
    //     };
    //   })
    //   .filter((el) => el.name != null);
    // this.columns.forEach((el) => {
    //   this.hide[el.value] = false;
    // });
    // let hiddenColumns = JSON.parse(localStorage.getItem("hiddenColumns"));
    // if (hiddenColumns !== null) {
    //   this.hiddenColumns = hiddenColumns;
    //   this.toggleColumn();
    // }
    // console.log(this.$refs.col);
    // this.$refs.col.hidden;
  },
  beforeUnmount() {
    this.updateCompanyInParent();
  },
  computed: {
    widthWindow() {
      let width = window.innerWidth;
      if (width < 640) {
        return "PrevPageLink JumpToPageInput CurrentPageReport NextPageLink RowsPerPageDropdown";
      } else {
        return "FirstPageLink PrevPageLink JumpToPageInput CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown";
      }
    },
    companyList() {
      return store.state.company.companyList.map(function (el) {
        return {
          id: el.id,
          name: el.full_name,
        };
      });
    },
  },
};
</script>

<style lang="scss">
.trn-all-table {
  .p-datatable-wrapper {
    overflow-x: hidden;
  }
  .p-datatable-wrapper {
    max-height: calc(100vh - 240px) !important;
    @media (min-width: 1440px) {
      max-height: calc(100vh - 280px) !important;
    }
  }
  &.p-datatable-sm .p-datatable-thead > tr > th,
  &.p-datatable-sm .p-datatable-tbody > tr > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  table {
    width: 100% !important;
    max-width: 100% !important;
  }
  .p-sortable-column.p-highlight,
  .p-sortable-column.p-highlight:hover {
    background: transparent;
    color: #343a40;
  }
}
.trn-wrp {
  padding-bottom: 30px;
  .p-column-filter-menu-button.p-column-filter-menu-button-active {
    overflow: visible;
    background: transparent !important;
    color: #6c757d !important;
    .pi.pi-filter-icon.pi-filter::after {
      content: "";
      position: absolute;
      margin-top: -6px;
      margin-right: -6px;
      width: 8px;
      height: 8px;
      background: var(--base);
      border-radius: 50%;
    }
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-active:hover,
  .p-column-filter-menu-button.p-column-filter-menu-button-open,
  .p-datatable .p-sortable-column.p-highlight,
  .p-column-filter-menu-button:hover {
    background: transparent !important;
    color: #6c757d !important;
  }

  .p-column-filter-buttonbar {
    gap: 1.5rem !important;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: transparent !important;
  }
  .p-0-super {
    .p-column-filter-buttonbar {
      padding: 0 !important;
    }
  }
  .p-column-title {
    order: 2;
  }
  .p-sortable-column-icon {
    order: 1;
  }
}
.p-0-super {
  z-index: 10011 !important;
  &.p-column-filter-overlay-menu {
    .p-column-filter-buttonbar,
    .p-column-filter-constraint {
      padding: 0.5rem 0.5rem !important;
    }
    .p-column-filter-buttonbar {
      background-color: var(--gray-400) !important;
    }
  }
}
.trn-all-pgn {
  bottom: 0;
  @media (max-width: 768px) {
    bottom: 65px;
  }
}
.panel-trn {
  z-index: 11015 !important;
  // .p-multiselect-items-wrapper {
  //   max-height: 50vh !important;
  // }
}
</style>